import React, { useEffect, useState } from 'react'
import {
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { getShipsByUser } from '../services/UserService'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    right: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    center: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    checkboxes: {
        margin: theme.spacing(3),
    },
    formGroup: {
        padding: theme.spacing(1, 3, 1, 3),
    },
    divider: {
        margin: theme.spacing(3),
    },
}))

const ShipsDropdown = ({ formik, fieldName, label, helperText, userId }) => {
    const classes = useStyles()
    const [ships, setShips] = useState()

    const [disabledField, setDisabledField] = useState(false)

    useEffect(() => {
        async function fetchShips() {
            const s = await getShipsByUser(userId)
            if (!!s && !!s.data && s.data.length > 0) {
                setShips(s)
                setDisabledField(false)
            } else {
                // if there's no ships => disable select
                setDisabledField(true)
            }
        }
        fetchShips()
    }, [userId])

    const [currentShip, setCurrentShip] = useState(null)

    const handleShipsChange = (event) => {
        const s = event.target.value
        setCurrentShip(s)
        if (!!s) {
            fillForm(s)
        } else {
            formik.resetForm()
        }
    }

    const onSelectClose = () => {
        // console.log('onSelectClose: ', event)
        setTimeout(() => {
            document.activeElement.blur()
        }, 0)
    }

    const fillForm = (ship) => {
        formik.setFieldValue('selected_ship', ship)
        formik.setFieldValue('ship_name', ship.ship_name)
        formik.setFieldValue('imo', ship.imo)
        formik.setFieldValue('flag', ship.flag)
        formik.setFieldValue('length', ship.length)
        formik.setFieldValue('beam', ship.beam)
        formik.setFieldValue('draft', ship.draft)
        formik.setFieldValue('gross_tonnage', ship.gross_tonnage)
        formik.setFieldValue('net_tonnage', ship.net_tonnage)
    }

    return (
        <Grid container className={classes.formGroup}>
            {formik && fieldName && (
                <Grid item xs={12} md={6} lg={6}>
                    <FormControl
                        fullWidth
                        className={classes.formControl}
                        disabled={disabledField}
                    >
                        <InputLabel id={`${fieldName}-label`}>
                            {label}
                        </InputLabel>
                        <Select
                            fullWidth
                            labelId={`${fieldName}-label`}
                            id={fieldName}
                            name={fieldName}
                            error={formik.errors[fieldName] && true}
                            {...formik.getFieldProps(fieldName)}
                            onChange={handleShipsChange}
                            value={currentShip || ''}
                            onClose={onSelectClose}
                        >
                            {/* {<MenuItem value={null}>v</MenuItem>} */}
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {ships &&
                                ships.data &&
                                ships.data.length > 0 &&
                                ships.data.map((s) => (
                                    <MenuItem key={s.ship_id} value={s}>
                                        {s.ship_name}
                                    </MenuItem>
                                ))}
                        </Select>
                        <FormHelperText>{helperText}</FormHelperText>
                    </FormControl>
                </Grid>
            )}
        </Grid>
    )
}

export default ShipsDropdown
