import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'

import {
    AppBar,
    TextField,
    Grid,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Typography,
    Divider,
    Checkbox,
    FormGroup,
    FormControlLabel,
    FormLabel,
    InputAdornment,
} from '@material-ui/core'
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDateTimePicker,
} from '@material-ui/pickers'
import { makeStyles } from '@material-ui/styles'
import DateFnsUtils from '@date-io/date-fns'
import { useFormik, useField, useFormikContext } from 'formik'
import * as Yup from 'yup'

import { getRoles, getUsersByRole } from '../redux/actions/user'
import {
    getBooking,
    createBooking,
    updateBooking,
    resetCurrentBooking,
} from '../redux/actions/booking'

import ClientsDropdown from './ClientsDropdown'
import ShipsDropdown from './ShipsDropdown'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    right: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    center: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    checkboxes: {
        margin: theme.spacing(3),
    },
    formGroup: {
        padding: theme.spacing(1, 3, 1, 3),
    },
    divider: {
        margin: theme.spacing(3),
    },
}))

const phoneRegexp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

// region
const DatePickerHelper = ({ formik, fieldName, label }) => {
    const [selectedDate, setSelectedDate] = useState(
        moment(new Date()).tz('Europe/Dublin').toDate()
    )
    const [disabled, setDisabled] = useState(false)

    const handleDateChange = (date) => {
        const arr = [
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
        ]
        const fixedDate = moment.tz(arr, 'Europe/Dublin').toDate()
        setSelectedDate(fixedDate)
        formik.setFieldValue(fieldName, fixedDate)
    }
    const isDisabled = () => {
        if (fieldName === 'eta') {
            return !formik.values.towin
        }
        if (fieldName === 'etd') {
            return !formik.values.towout
        }
        if (fieldName === 'est') {
            return !formik.values.shift
        }
    }

    useEffect(() => {
        setDisabled(isDisabled())
    }, [formik.values])

    return (
        <Grid container>
            <Grid item xs={12}>
                <KeyboardDateTimePicker
                    id={`date-picker-${fieldName}`}
                    disabled={disabled && true}
                    error={formik.errors[fieldName] && true}
                    name={`${fieldName}_helper`}
                    clearable="true"
                    ampm={false}
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy HH:mm"
                    margin="normal"
                    label={label}
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                <input
                    type="hidden"
                    name={fieldName}
                    {...formik.getFieldProps(fieldName)}
                />
            </Grid>
        </Grid>
    )
}
// endregion

// region
const ServiceSelector = ({ formik }) => {
    const classes = useStyles()
    const [towin, setTowin] = useState(false)
    const [towout, setTowout] = useState(false)
    const [shift, setShift] = useState(false)

    const formData = useSelector((state) => state.booking.currentBooking)

    useEffect(() => {
        if (formData && formData.towin) setTowin(formData.towin)
        if (formData && formData.towout) setTowin(formData.towout)
        if (formData && formData.shift) setTowin(formData.shift)
    }, [formData])

    const handleChange = (event) => {
        if (event.target.name === 'shift') {
            if (event.target.checked) {
                setTowin(false)
                formik.setFieldValue('towin', false)
                setTowout(false)
                formik.setFieldValue('towout', false)
            }
            setShift(event.target.checked)
            formik.setFieldValue('shift', event.target.checked)
        } else {
            if (shift && event.target.checked) {
                setShift(false)
                formik.setFieldValue('shift', false)
            }
            if (event.target.name === 'towin') {
                setTowin(event.target.checked)
                formik.setFieldValue('towin', event.target.checked)
            }
            if (event.target.name === 'towout') {
                setTowout(event.target.checked)
                formik.setFieldValue('towout', event.target.checked)
            }
        }
    }

    return (
        <Grid container direction="column">
            <Grid item xs={12}>
                <FormControl
                    required
                    error={
                        (formik.errors.towin ||
                            formik.errors.towout ||
                            formik.errors.shift) &&
                        true
                    }
                    component="fieldset"
                    className={classes.checkboxes}
                >
                    <FormLabel component="legend">
                        Select shift only or tow in/tow out
                    </FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={towin}
                                    onChange={handleChange}
                                    name="towin"
                                />
                            }
                            label="Tow in"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={towout}
                                    onChange={handleChange}
                                    name="towout"
                                />
                            }
                            label="Tow out"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={shift}
                                    onChange={handleChange}
                                    name="shift"
                                />
                            }
                            label="Shift"
                        />
                    </FormGroup>
                </FormControl>
            </Grid>
        </Grid>
    )
}
// endregion

const BookingForm = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const { bookingId } = useParams()
    const [bookingData, setBookingData] = useState(null)

    const roles = useSelector((state) => state.user.roles)
    const {
        role_name: activeRole,
        id: currentUserId,
        firstname,
        lastname,
        username,
    } = useSelector((state) => {
        // console.log('SELECTOR AUTH: ' + state.auth.user.id)
        return state.auth.user
    })

    const currentUserFullName = `${firstname || ''} ${
        lastname || 'no name'
    }, ${username}`

    const agents = useSelector((state) => {
        const { roles, usersInRole } = state.user
        const agentRole = roles
            ? roles.find((r) => r.name === 'agent')
            : undefined
        if (agentRole && usersInRole) {
            return usersInRole[agentRole.role_id]
        } else {
            return undefined
        }
    })

    const owners = useSelector((state) => {
        const { roles, usersInRole } = state.user
        const ownerRole = roles
            ? roles.find((r) => r.name === 'ship_owner')
            : undefined
        if (ownerRole && usersInRole) {
            return usersInRole[ownerRole.role_id]
        } else {
            return undefined
        }
    })
    const bookingState = useSelector((state) => {
        // console.log('SELECTOR')
        return state.booking.currentBooking
    })
    const messages = useSelector((state) => state.booking.messages)

    const usersInRole = useSelector((state) => state.user.usersInRole)
    useEffect(() => {
        if (!roles || roles.length === 0) {
            dispatch(getRoles())
        } else {
            const agentRoleId = roles.find((r) => r.name === 'agent').role_id
            const ownerRoleId = roles.find((r) => r.name === 'ship_owner')
                .role_id
            dispatch(getUsersByRole(agentRoleId))
            dispatch(getUsersByRole(ownerRoleId))
        }
    }, [roles])

    const NOW = moment(new Date()).tz('Europe/Dublin').toDate()

    // region
    const formik = useFormik({
        initialValues: {
            ...(bookingId ? { booking_id: bookingId } : {}),
            ship_name: '',
            imo: '',
            flag: '',
            length: '',
            beam: '',
            draft: '',
            gross_tonnage: '',
            net_tonnage: '',
            owner: '',
            client: '',
            towin: '',
            towout: '',
            shift: '',
            eta: NOW,
            etd: NOW,
            est: NOW,
            selected_ship: '',
            status: 'pending',
        },
        validationSchema: () => {
            return Yup.object({
                ship_name: Yup.string().required('Required'),
                imo: Yup.number().required('Required'),
                flag: Yup.string().required('Required'),
                length: Yup.number().required('Required'),
                beam: Yup.number().required('Required'),
                draft: Yup.number().required('Required'),
                gross_tonnage: Yup.number().required('Required'),
                net_tonnage: Yup.number().required('Required'),
                owner: Yup.number().required('Required'),

                client: Yup.string(),

                towin: Yup.bool()
                    .nullable()
                    .test(
                        'towin-validation',
                        'Select at least one of towin/towout or shift',
                        function (value) {
                            return (
                                this.parent.towin === true ||
                                this.parent.towout === true ||
                                this.parent.shift === true
                            )
                        }
                    ),
                towout: Yup.bool()
                    .nullable()
                    .test(
                        'towin-validation',
                        'Select at least one of towin/towout or shift',
                        function (value) {
                            return (
                                this.parent.towin === true ||
                                this.parent.towout === true ||
                                this.parent.shift === true
                            )
                        }
                    ),
                shift: Yup.bool()
                    .nullable()
                    .test(
                        'towin-validation',
                        'Select at least one of towin/towout or shift',
                        function (value) {
                            return (
                                this.parent.towin === true ||
                                this.parent.towout === true ||
                                this.parent.shift === true
                            )
                        }
                    ),
                eta: Yup.date()
                    .nullable()
                    .test('eta-validation', 'Past dates not allowed', function (
                        dt
                    ) {
                        if (!this.parent.towin) return true
                        if (this.parent.shift === true) return true
                        const etaBefore = this.parent.towout
                            ? dt < this.parent.etd
                            : true
                        return dt >= NOW && etaBefore
                    }),
                etd: Yup.date()
                    .nullable()
                    .test('etd-validation', 'Past dates not allowed', function (
                        dt
                    ) {
                        if (!this.parent.towout) return true
                        if (this.parent.shift === true) return true
                        const etdAfter = this.parent.eta
                            ? dt > this.parent.eta
                            : true
                        return dt >= NOW && etdAfter
                    }),
                est: Yup.date()
                    .nullable()
                    .test('est-validation', 'Past dates not allowed', function (
                        dt
                    ) {
                        // ignore if not shfit service
                        if (!this.parent.shift) return true
                        return dt >= NOW
                    }),
                status: Yup.string().required('Required'),
            })
        },
        onSubmit: (values) => {
            bookingId
                ? dispatch(updateBooking(values))
                : dispatch(createBooking(values))
        },
    })
    // endregion

    // init booking form from redux state, if set
    useEffect(() => {
        if (bookingState) {
            // console.log('INIT BOOKING DATA')

            setBookingData({ ...bookingState })
        }
        return () => dispatch(resetCurrentBooking())
    }, [bookingState])

    // init form values from bookingData
    useEffect(() => {
        // console.log('BOOKING DATA ' + bookingData)
        if (bookingData) {
            Object.keys(bookingData).forEach((field) => {
                formik.setFieldValue(field, bookingData[field] || '', false)
            })
        } else {
            formik.resetForm()
            formik.setErrors({})
        }
    }, [bookingData])

    useEffect(() => {
        bookingId
            ? dispatch(getBooking(bookingId))
            : dispatch(resetCurrentBooking())
    }, [bookingId])

    useEffect(() => {
        if (bookingId) {
            if (messages.update && messages.update.severity) {
                if (messages.update.severity === 'success') {
                    dispatch(resetCurrentBooking())
                    formik.resetForm()
                    setTimeout(() => history.push('/u/my-bookings'), 500)
                }
            }
        } else {
            if (messages.create && messages.create.severity) {
                if (messages.create.severity === 'success') {
                    dispatch(resetCurrentBooking())
                    formik.resetForm()
                    setTimeout(
                        () => history.push('/u/my-bookings/booking/sent'),
                        500
                    )
                }
            }
        }
    }, [messages])

    useEffect(() => {
        // console.log(formik.errors)
        if (
            formik.errors.owner &&
            (activeRole === 'agent' || activeRole === 'ship_owner')
        ) {
            formik.setFieldValue('owner', currentUserId)
        }
    }, [formik.errors])

    const txtProps = {
        lg: 5,
        md: 6,
        xs: 12,
        className: classes.formGroup,
    }

    const [selectedShip, setSelectedShip] = useState(false)

    useEffect(() => {
        setSelectedShip(isShipSelected())
    }, [formik.values])

    const isShipSelected = () => {
        return !!formik && !!formik.values && !!formik.values.selected_ship
    }

    return (
        <div className={classes.root}>
            <form
                className={classes.root}
                noValidate
                autoComplete="off"
                onSubmit={formik.handleSubmit}
            >
                <Grid container spacing={3} direction="column">
                    <Grid
                        container
                        className={classes.formGroup}
                        direction="row"
                    >
                        {/* col 1 */}
                        <Grid item xs={12} md={3} lg={4}>
                            {/* admin */}
                            {activeRole === 'admin' && (
                                <Grid item xs={12}>
                                    <ClientsDropdown
                                        formik={formik}
                                        fieldName="owner"
                                        label="Booking Owner"
                                        helperText=""
                                    />
                                    <Grid
                                        container
                                        className={classes.formGroup}
                                    >
                                        <TextField
                                            fullWidth
                                            name="client"
                                            label="Client name"
                                            variant="outlined"
                                            margin="dense"
                                            readOnly={true}
                                            error={formik.errors.client && true}
                                            {...formik.getFieldProps('client')}
                                        />
                                    </Grid>
                                </Grid>
                            )}

                            {/* owner */}
                            {activeRole === 'ship_owner' && (
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        name="_owner"
                                        label="Ship owner"
                                        variant="outlined"
                                        margin="dense"
                                        readOnly={true}
                                        value={currentUserFullName}
                                        error={formik.errors.owner && true}
                                    />
                                    <input
                                        type="hidden"
                                        name="owner"
                                        {...formik.getFieldProps('owner')}
                                    />
                                    <TextField
                                        fullWidth
                                        name="client"
                                        label="Agent name"
                                        variant="outlined"
                                        margin="dense"
                                        error={formik.errors.client && true}
                                        {...formik.getFieldProps('client')}
                                    />
                                </Grid>
                            )}

                            {/*  agent */}
                            {activeRole === 'agent' && (
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        name="client"
                                        label="Ship owner"
                                        variant="outlined"
                                        margin="dense"
                                        error={formik.errors.owner && true}
                                        {...formik.getFieldProps('client')}
                                    />
                                    <input
                                        type="hidden"
                                        name="owner"
                                        {...formik.getFieldProps('owner')}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        {/* col 2 */}
                        <Grid
                            item
                            xs={12}
                            md={5}
                            lg={3}
                            className={classes.formGroup}
                        >
                            {/* towin: 'towin',*/}
                            <Grid item xs={12}>
                                <ServiceSelector formik={formik} />
                            </Grid>
                            {/* DATE FIELDS */}
                            <Grid item xs={12} className={classes.formGroup}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    {/* eta: '2017-05-24T10:30',*/}
                                    <Grid item xs={12}>
                                        <Grid item xs={12}>
                                            <DatePickerHelper
                                                formik={formik}
                                                fieldName="eta"
                                                label="Estimated time arrival"
                                            />
                                        </Grid>
                                    </Grid>
                                    {/* etd: '2017-05-24T10:30',*/}
                                    <Grid item xs={12}>
                                        <Grid item xs={12}>
                                            <DatePickerHelper
                                                formik={formik}
                                                fieldName="etd"
                                                label="Estimated time departure"
                                            />
                                        </Grid>
                                    </Grid>
                                    {/* est: '2017-05-24T10:30',*/}
                                    <Grid item xs={12}>
                                        <Grid item xs={12}>
                                            <DatePickerHelper
                                                formik={formik}
                                                fieldName="est"
                                                label="Estimated shif time"
                                            />
                                        </Grid>
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>

                    {/* BLOCK 2 */}
                    <Grid
                        container
                        className={classes.formGroup}
                        direction="row"
                    >
                        <Grid
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            className={classes.formGroup}
                        >
                            <Grid item xs={12}>
                                <ShipsDropdown
                                    formik={formik}
                                    fieldName="selected_ship"
                                    label="Ships"
                                    helperText=""
                                    userId={'2'}
                                    {...formik.getFieldProps('selected_ship')}
                                />
                            </Grid>
                        </Grid>

                        {/*  ship_name */}
                        <Grid item {...txtProps}>
                            <Grid item xs={12}>
                                <TextField
                                    error={formik.errors.ship_name && true}
                                    fullWidth
                                    name="ship_name"
                                    label="ship_name"
                                    variant="outlined"
                                    margin="dense"
                                    {...formik.getFieldProps('ship_name')}
                                    disabled={selectedShip}
                                />
                            </Grid>
                        </Grid>

                        {/*  imo */}
                        <Grid item {...txtProps}>
                            <Grid item xs={12}>
                                <TextField
                                    error={formik.errors.imo && true}
                                    fullWidth
                                    name="imo"
                                    label="imo"
                                    variant="outlined"
                                    margin="dense"
                                    {...formik.getFieldProps('imo')}
                                    disabled={selectedShip}
                                />
                            </Grid>
                        </Grid>

                        {/*  flag */}
                        <Grid item {...txtProps}>
                            <Grid item xs={12}>
                                <TextField
                                    error={formik.errors.flag && true}
                                    fullWidth
                                    name="flag"
                                    label="flag"
                                    variant="outlined"
                                    margin="dense"
                                    {...formik.getFieldProps('flag')}
                                    disabled={selectedShip}
                                />
                            </Grid>
                        </Grid>

                        {/*  length */}
                        <Grid item {...txtProps}>
                            <Grid item xs={12}>
                                <TextField
                                    error={formik.errors.length && true}
                                    fullWidth
                                    name="length"
                                    label="length"
                                    variant="outlined"
                                    margin="dense"
                                    {...formik.getFieldProps('length')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                m
                                            </InputAdornment>
                                        ),
                                    }}
                                    disabled={selectedShip}
                                />
                            </Grid>
                        </Grid>

                        {/*  beam */}
                        <Grid item {...txtProps}>
                            <Grid item xs={12}>
                                <TextField
                                    error={formik.errors.beam && true}
                                    fullWidth
                                    name="beam"
                                    label="beam"
                                    variant="outlined"
                                    margin="dense"
                                    {...formik.getFieldProps('beam')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                m
                                            </InputAdornment>
                                        ),
                                    }}
                                    disabled={selectedShip}
                                />
                            </Grid>
                        </Grid>

                        {/* draft: 'draft',*/}
                        <Grid item {...txtProps}>
                            <Grid item xs={12}>
                                <TextField
                                    error={formik.errors.draft && true}
                                    fullWidth
                                    name="draft"
                                    label="draft"
                                    variant="outlined"
                                    margin="dense"
                                    {...formik.getFieldProps('draft')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                m
                                            </InputAdornment>
                                        ),
                                    }}
                                    disabled={selectedShip}
                                />
                            </Grid>
                        </Grid>
                        {/* gross_tonnage: 'gross_tonnage',*/}
                        <Grid item {...txtProps}>
                            <Grid item xs={12}>
                                <TextField
                                    error={formik.errors.gross_tonnage && true}
                                    fullWidth
                                    name="gross_tonnage"
                                    label="gross_tonnage"
                                    variant="outlined"
                                    margin="dense"
                                    {...formik.getFieldProps('gross_tonnage')}
                                    disabled={selectedShip}
                                />
                            </Grid>
                        </Grid>
                        {/* net_tonnage: 'net_tonnage',*/}
                        <Grid item {...txtProps}>
                            <Grid item xs={12}>
                                <TextField
                                    error={formik.errors.net_tonnage && true}
                                    fullWidth
                                    name="net_tonnage"
                                    label="net_tonnage"
                                    variant="outlined"
                                    margin="dense"
                                    {...formik.getFieldProps('net_tonnage')}
                                    disabled={selectedShip}
                                />
                            </Grid>
                        </Grid>

                        {/* status: 'pending',*/}

                        {activeRole !== 'admin' && (
                            <Grid item {...txtProps}>
                                <Grid item xs={12}>
                                    <input
                                        type="hidden"
                                        name="status"
                                        {...formik.getFieldProps('status')}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        {activeRole === 'admin' && (
                            <Grid item {...txtProps}>
                                <Grid item xs={12}>
                                    <FormControl
                                        fullWidth
                                        className={classes.formControl}
                                    >
                                        <InputLabel id="status-label">
                                            Booking status
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            labelId="status-label"
                                            id="status"
                                            name="status"
                                            error={formik.errors.status && true}
                                            {...formik.getFieldProps('status')}
                                        >
                                            <MenuItem value="pending">
                                                Pending
                                            </MenuItem>
                                            <MenuItem value="confirmed">
                                                Confirmed
                                            </MenuItem>
                                            <MenuItem value="rejected">
                                                Rejected
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <Divider className={classes.divider} />
                        </Grid>

                        <Grid item xs={10} className={classes.center}>
                            {bookingId && (
                                <input
                                    type="hidden"
                                    name="booking_id"
                                    value={bookingId}
                                    {...formik.getFieldProps('booking_id')}
                                />
                            )}
                            <Button
                                type="submit"
                                size="medium"
                                variant="contained"
                                color="primary"
                            >
                                Send booking
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default BookingForm
