import { parseGetParams, obj2query } from '../utils/Http'
import { jsonCall } from './Gateway'

export const createUser = (body) => {
    const jwtResp = jsonCall(`/user/create`, 'POST', body)
    return jwtResp
}

export const updateUser = (body) => {
    const jwtResp = jsonCall(`/user/update`, 'PUT', body)
    return jwtResp
}

export const getUsersList = (status, filter, offset, limit, sort) => {
    const jwtResp = jsonCall(
        `/user/list${parseGetParams({ status, filter, offset, limit, sort })}`,
        'GET'
    )
    return jwtResp
}

export const getUsersByRole = (id) => {
    const jwtResp = jsonCall(`/user/byRole${parseGetParams({ id })}`, 'GET')
    return jwtResp
}

export const getShipsByUser = (userId) => {
    const jwtResp = jsonCall(`/ship/byUserId/${userId}`, 'GET')
    return jwtResp
}

export const getUser = (id) => {
    const jwtResp = jsonCall(`/user/${id}`, 'GET')
    return jwtResp
}

export const getRoles = () => {
    const jwtResp = jsonCall('/user/roles', 'GET')
    return jwtResp
}

export const blockUser = (userId) => {
    const jwtResp = jsonCall('/user/block', 'PUT', {
        userId,
    })
    return jwtResp
}

export const deleteUser = (userId) => {
    const jwtResp = jsonCall('/user/delete', 'PUT', {
        userId,
    })
    return jwtResp
}

export const activateUser = (userId) => {
    const jwtResp = jsonCall('/user/activate', 'PUT', {
        userId,
    })
    return jwtResp
}

export const requestPassword = (username, email) => {
    const jwtResp = jsonCall('/password/request', 'PUT', {
        username,
        email,
    })
    return jwtResp
}
