import { makeStyles } from '@material-ui/styles'
import {
    TextField,
    Button,
    Grid,
    Container,
    Card,
    Typography,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import React, { useState, useCallback, useEffect } from 'react'

import { login } from '../redux/actions/auth'

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        backgroundColor: '#fff',
        padding: '2rem',
        borderRadius: '.25rem',
    },
    item: {
        margin: '.5rem',
    },
})

const LoginForm = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [username, setUsername] = useState(null)
    const [password, setPassword] = useState(null)
    const doLogin = useCallback(() => dispatch(login(username, password)), [
        username,
        password,
        dispatch,
    ])

    const keyDownHandler = (e) => {
        if (e.keyCode === 13) {
            doLogin()
        }
    }

    return (
        <Container maxWidth="xs">
            <Grid container>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}>
                    <Card>
                        <form
                            className={classes.container}
                            noValidate
                            autoComplete="off"
                            onKeyDown={keyDownHandler}
                        >
                            <TextField
                                className={classes.item}
                                required
                                label="Username"
                                type="username"
                                value={username || ''}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            <TextField
                                className={classes.item}
                                required
                                label="Password"
                                type="password"
                                value={password || ''}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Button
                                className={classes.item}
                                variant="contained"
                                color="primary"
                                onClick={doLogin}
                            >
                                sign in
                            </Button>
                        </form>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}

export default LoginForm
