import React, { useEffect } from 'react'

import {
    Route,
    Redirect,
    Switch,
    useHistory,
    useLocation,
} from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
// pages
import LoginPage from '../pages/LoginPage'
import BookingFormPage from '../pages/BookingFormPage'
import BookingDetailsPage from '../pages/BookingDetailsPage'
import BookingDonePage from '../pages/BookingDonePage'
import MyBookingsPage from '../pages/MyBookingsPage'
import CommercialBookingsPage from '../pages/CommercialBookingsPage'
import OperationsBookingsPage from '../pages/OperationsBookingsPage'
import VtsPage from '../pages/VtsPage'
import CreateUserPage from '../pages/CreateUserPage'
import AdminHomePage from '../pages/AdminHomePage'
import SettingsPage from '../pages/SettingsPage'
import PwtBreadcrumbs from '../components/PwtBreadcrumbs'
import { resetNotifier } from '../redux/actions/notifier'

const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            justifyContent: 'flex-start',
            background: `linear-gradient(180deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.default} 30%, ${theme.palette.grey[300]} 100%)`,
            height: '100vh',
            position: 'relative',
            overflowY: 'visible',
            overflowX: 'hidden',
        },
        page: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'start',
            height: 'calc(90% - 64px)',
            padding: '1rem',
            position: 'relative',
            margin: '64px 0',
            overflowY: 'visible',
        },
    }
})

const RootPanel = ({ children }) => {
    const classes = useStyles()
    const { isLoggedIn, user } = useSelector((state) => state.auth)
    const history = useHistory()
    const dispatch = useDispatch()
    const location = useLocation()
    const isOpenLocation = !location.pathname.startsWith('/u')
    const currentRole = useSelector((state) =>
        state.auth.user ? state.auth.user.role_name : null
    )

    const { enqueueSnackbar } = useSnackbar()
    const message = useSelector((state) => state.notifier.message)
    useEffect(() => {
        message &&
            message.severity &&
            enqueueSnackbar(message.body, {
                variant: message.severity,
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                preventDuplicate: true,
            })
        message &&
            message.severity &&
            setTimeout(() => {
                dispatch(resetNotifier())
            }, 1000)
    }, [message])

    useEffect(() => {
        if (isLoggedIn === true && user !== null) {
            switch (user.role_name) {
                case 'admin':
                    if (!history.location.pathname.startsWith('/u/my-bookings'))
                        history.replace('/u/my-bookings')
                    break
                case 'agent':
                    if (!history.location.pathname.startsWith('/u/my-bookings'))
                        history.replace('/u/my-bookings')
                    break
                case 'ship_owner':
                    if (!history.location.pathname.startsWith('/u/my-bookings'))
                        history.replace('/u/my-bookings')
                    break
                case 'commercial':
                    if (!history.location.pathname.startsWith('/u/my-bookings'))
                        history.replace('/u/my-bookings')
                    break
                case 'operations':
                    if (!history.location.pathname.startsWith('/u/my-bookings'))
                        history.replace('/u/my-bookings')
                    break
                case 'vts':
                    if (!history.location.pathname.startsWith('/u/my-bookings'))
                        history.replace('/u/my-bookings')
                    break
                default:
                    history.replace('/login')
                    break
            }
        }
        return () => {}
    }, [user, isLoggedIn, dispatch, history])

    useEffect(() => {
        isLoggedIn === false && localStorage.clear()
        !isLoggedIn && !isOpenLocation && history.push('/login')
    }, [isLoggedIn])

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            className={classes.page}
        >
            {!isLoggedIn && isOpenLocation && (
                <Switch>
                    <Route exact path={'/'}>
                        <Redirect to="/login" />
                    </Route>
                    <Route path={'/login/:message?'} component={LoginPage} />
                </Switch>
            )}

            {isLoggedIn && !isOpenLocation && (
                <Grid item xs={12}>
                    <PwtBreadcrumbs />
                    <Switch>
                        {currentRole === 'admin' && (
                            <Route
                                exact
                                path="/u/users"
                                component={AdminHomePage}
                            />
                        )}
                        {currentRole === 'admin' && (
                            <Route
                                exact
                                path="/u/users/create"
                                component={CreateUserPage}
                            />
                        )}
                        {currentRole === 'admin' && (
                            <Route
                                exact
                                path="/u/users/:userId"
                                component={CreateUserPage}
                            />
                        )}

                        <Route
                            exact
                            path="/u/settings"
                            component={SettingsPage}
                        />
                        <Route
                            exact
                            path="/u/my-bookings"
                            component={MyBookingsPage}
                        />
                        <Route
                            exact
                            path="/u/my-bookings/booking"
                            component={BookingFormPage}
                        />
                        <Route
                            exact
                            path="/u/my-bookings/booking/sent"
                            component={BookingDonePage}
                        />
                        <Route
                            path="/u/my-bookings/booking/:bookingId"
                            component={BookingFormPage}
                        />
                        <Route
                            path="/u/my-bookings/details/:bookingId"
                            component={BookingDetailsPage}
                        />
                    </Switch>
                </Grid>
            )}
        </Grid>
    )
}

export default RootPanel
