import { parseGetParams, obj2query } from '../utils/Http'
import { jsonCall } from './Gateway'

export const createBooking = (body) => {
    const jwtResp = jsonCall('/booking/create', 'POST', body)
    return jwtResp
}

export const updateBooking = (body) => {
    const jwtResp = jsonCall('/booking/update', 'PUT', body)
    return jwtResp
}

export const getBookings = (filter, offset, limit, sort) => {
    const jwtResp = jsonCall(
        `/booking/list${parseGetParams({ filter, offset, limit, sort })}`,
        'GET'
    )
    return jwtResp
}

export const getPastBookings = (filter, offset, limit, sort) => {
    const jwtResp = jsonCall(
        `/booking/list/past${parseGetParams({ filter, offset, limit, sort })}`,
        'GET'
    )
    return jwtResp
}

export const getCurrentBookings = (filter, offset, limit, sort) => {
    const jwtResp = jsonCall(
        `/booking/list/current${parseGetParams({
            filter,
            offset,
            limit,
            sort,
        })}`,
        'GET'
    )
    return jwtResp
}

export const getBooking = (id) => {
    const jwtResp = jsonCall(`/booking/get/${id}`, 'GET')
    return jwtResp
}

export const confirmBooking = (body) => {
    const jwtResp = jsonCall(`/booking/confirm`, 'PUT', body)
    return jwtResp
}
